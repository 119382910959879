<template>
  <div class="page-heading">
    <div class="panel-header">
      <h1>{{ $t("todos.main.title") }}</h1>
    </div>
    <Overview />
  </div>
</template>

<script>
import Overview from "./Overview.vue";

export default {
  name: "PageHeading",
  components: {
    Overview,
  },
};
</script>

<style lang="scss" scoped>
.page-heading {
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
}
.panel-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  flex: none;
  justify-content: space-between;
  background: #dfe4e9;

  h1 {
    font-size: 24px;
    line-height: 32px;
    margin: 0;
    @include font_weight("Semibold");
  }
}
</style>
