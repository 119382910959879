<template>
  <div class="page">
    <DgSpinner v-if="isLoading" class="page-spinner" />
    <div v-else class="container">
      <div class="content">
        <PageHeading />
        <TodoList />
        <Contacts />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import PageHeading from "../components/layout/PageHeading.vue";
import Contacts from "../components/layout/Contacts.vue";
import TodoList from "../components/todo/TodoList.vue";

export default {
  name: "ToDo",
  components: {
    PageHeading,
    Contacts,
    TodoList,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  async created() {
    this.fetchData();
  },
  destroyed() {
    this.$store.commit("toggleAddForm", false);
  },
  methods: {
    ...mapActions(["fetchTodos", "fetchAvailableAssignees"]),
    async fetchData() {
      try {
        await this.fetchTodos();
        await this.fetchAvailableAssignees();
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  --page-header-hight: 65px;
  --page-footer-height: 70px;
  --page-content-min: calc(100vh - var(--page-header-hight) - var(--page-footer-height));
  display: flex;
  justify-content: center;
  min-height: var(--page-content-min);
}
.page-spinner {
  display: flex;
  justify-content: center;
  align-self: center;
}
.container {
  max-width: 990px;
  padding-block: 32px;
}
.content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
</style>
<!-- Quick fix to bring all controls to one min-height -->
<style lang="scss">
.todo-form-wrapper {
  button,
  select,
  textarea,
  input,
  .assignee,
  .priority,
  .dg-ds-button {
    min-height: 44px;
  }
}
</style>
