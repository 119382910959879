<template>
  <div v-click-outside="closeDropdown" class="assignee">
    <div class="assignee-value" @click="toggleDropDown">
      <div class="title">{{ currentAssignee }}</div>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7 8L12 3L17 8"
          stroke="#7E93A7"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17 16L12 21L7 16"
          stroke="#7E93A7"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <div v-if="showDropDown" class="assignee-list" :class="{ inverted: !shouldOpenBelow }">
      <div class="label">{{ $t("todos.new_todo.assignee") }}</div>
      <div class="unassigned" @click="onSelect(null)">{{ $t("todos.new_todo.unassigned") }}</div>
      <div class="inbox">
        <div
          v-for="(item, key) in availableAssignees"
          :key="key"
          :class="['item', { active: assignee === item.id }]"
          @click="onSelect(item.id)"
        >
          <div class="name">{{ item.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    assignee: {
      type: [Number, String],
      default: null,
    },
  },
  data: () => ({
    showDropDown: false,
    distanceToBottomEdge: 0,
  }),
  computed: {
    ...mapState({
      availableAssignees: state => state.todos.availableAssignees,
    }),
    currentAssignee() {
      try {
        return this.availableAssignees[this.assignee].name;
      } catch {
        return this.$t("todos.new_todo.unassigned");
      }
    },
    shouldOpenBelow() {
      return this.distanceToBottomEdge > 240;
    },
  },
  created() {
    document.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    onSelect(assignee) {
      this.closeDropdown();
      this.$emit("select", assignee);
    },
    onScroll() {
      const { bottom } = this.$el.getBoundingClientRect();
      this.distanceToBottomEdge = window.innerHeight - bottom;
    },
    toggleDropDown() {
      this.showDropDown = !this.showDropDown;
    },
    closeDropdown() {
      this.showDropDown = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.assignee {
  box-shadow: 0 0 0 1px #7e93a7 inset;
  display: inline-flex;
  align-items: center;
  background: #fff;
  min-width: 200px;
  position: relative;
}
.assignee-list {
  position: absolute;
  left: 0;
  top: 40px;
  background: #fff;
  width: 100%;
  z-index: 3;
  border: 1px solid #c9d2d7;
  box-shadow: 0px 2px 12px 1px rgba(0, 0, 0, 0.15);

  &.inverted {
    top: auto;
    bottom: 40px;
  }
}
.assignee-list .item {
  padding: 5px;
  cursor: pointer;
}
.assignee-list .inbox {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 200px;
}
.assignee-list .unassigned {
  padding: 10px;
  cursor: pointer;
}
.assignee-list .label {
  font-weight: 700;
  font-size: 16px;
  display: block;
  padding: 10px;
}
.assignee-list .unassigned:hover,
.assignee-list .item:hover,
.assignee-list .item.active {
  background: #dfe4e9;
}
.assignee-list .item .name {
  font-size: 13px;
  padding: 2px 8px;
  width: 100%;
}
.assignee-value {
  display: flex;
  align-items: center;
  cursor: pointer;
  min-width: 220px;
}
.assignee-value .title {
  width: 100%;
  font-size: 0.875rem;
  padding-inline: 0.75rem;
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.assignee-value svg {
  width: 50px;
  margin: 7px 0;
}
</style>
