<template>
  <div class="todo-item" :class="{ active: areDetailsOpen }">
    <div @click="toggleDetails">
      <TodoPreview class="preview" :todo="todo" :are-details-open="areDetailsOpen" />
    </div>
    <div v-if="areDetailsOpen" class="details">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div class="description" v-html="markdown(todoDesription)"></div>
      <div class="details-actions">
        <DgButton
          v-if="isUserDpo"
          variant-style="outline"
          :disabled="todo.showEditForm"
          @click="isConfirmationOpen = true"
        >
          <img src="../../images/trash.svg" alt="trash-symbol" class="todo-description__button_symbol" />
          {{ $t("todos.remove") }}
        </DgButton>
        <DgButton variant-style="secondary" :disabled="isEditFormOpen" @click="openEdit">
          <img src="../../images/edit.svg" alt="edit-symbol" class="todo-description__button_symbol" />
          {{ $t("todos.edit") }}
        </DgButton>
      </div>
      <Hint v-if="!isEditFormOpen && daysTillDeadline > 2">{{ emailMessage }}</Hint>
      <DeleteTodoModalConfirm
        v-if="isUserDpo"
        :task-id="todo.id"
        :show="isConfirmationOpen"
        @close="isConfirmationOpen = false"
        @hide-description="showDescription = false"
      />
    </div>
    <ToDoForm v-if="isEditFormOpen" :is-new-task="false" :todo-data="todo" @cancel="onCancel" />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { differenceInCalendarDays } from "date-fns";
import DeleteTodoModalConfirm from "../modals/DeleteTodoConfirmModal.vue";
import Hint from "../layout/Hint.vue";
import ToDoForm from "./ToDoForm.vue";
import TodoPreview from "./TodoPreview.vue";
export default {
  name: "TodoItem",
  components: {
    DeleteTodoModalConfirm,
    TodoPreview,
    ToDoForm,
    Hint,
  },
  props: {
    todo: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    areDetailsOpen: false,
    isConfirmationOpen: false,
  }),
  computed: {
    ...mapState({
      editForm: state => state.todos.editForm,
      user: state => state.user.userData,
      availableAssignees: state => state.todos.availableAssignees,
    }),
    isEditFormOpen() {
      if (this.editForm.id !== this.todo.id) return false;
      return this.editForm.isOpen;
    },
    isUserDpo() {
      return this.user.role.includes("dpo");
    },
    todoDesription() {
      return this.todo.description || this.$t("todos.no_details");
    },
    daysTillDeadline() {
      try {
        const { deadline_date } = this.todo;
        if (!deadline_date.toString().length) return 0;
        return differenceInCalendarDays(new Date(deadline_date), new Date());
      } catch (error) {
        return 0;
      }
    },
    emailMessage() {
      return this.daysTillDeadline > 13
        ? this.$t("todos.email_message.client.big")
        : this.$t("todos.email_message.client.small");
    },
  },
  methods: {
    ...mapActions(["tryOpenForm"]),
    onCancel() {
      this.$store.commit("closeEditForm");
    },
    toggleDetails() {
      if (this.areDetailsOpen) {
        this.$store.commit("closeEditForm");
      }
      this.areDetailsOpen = !this.areDetailsOpen;
    },
    openEdit() {
      this.tryOpenForm({
        type: "edit",
        id: this.todo.id,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.todo-item {
  padding: 0.5rem 1rem;
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  background-color: $white;

  &.active,
  &:hover {
    background-color: rgba(223, 228, 233, 0.2);
  }
}
.details {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;
  padding-left: calc(1rem + 25px);
}
.description {
  padding: 0.75rem;
  background-color: #f2f4f6;
  font-size: 12px;
  white-space: pre-line;
  word-break: break-word;
}
.details-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
}
button.dg-ds-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
</style>
