<template>
  <div class="dpo-section">
    <button class="form-toggle" @click="toggleForm" :disabled="showAddForm">
      <img src="../../images/plus-red.svg" />
      <span>{{ $t("todos.main.add_btn") }}</span>
    </button>
    <div v-if="showAddForm" class="form-container">
      <ToDoForm :is-new-task="true" @cancel="onEditCancel" @success="successAddTodo" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ToDoForm from "./ToDoForm.vue";
export default {
  name: "TodoAdd",
  components: {
    ToDoForm,
  },
  computed: {
    ...mapState({
      editForm: state => state.todos.editForm,
      showAddForm: state => state.todos.showAddForm,
    }),
  },
  methods: {
    ...mapActions(["fetchTodos", "tryOpenForm"]),
    toggleForm() {
      this.tryOpenForm({ type: "create" });
    },
    onEditCancel() {
      this.$store.commit("toggleAddForm", false);
    },
    successAddTodo() {
      this.fetchTodos();
      this.$store.commit("toggleAddForm", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.dpo-section {
  border-top: 1px $lightgrey solid;
}
.form-toggle {
  border: none;
  outline: none;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem 1rem;
  color: $red;
  transition: background-color 0.2s ease-in-out;
  background-color: $lightblue;

  &:is(:hover, :active, :focus) {
    background-color: $lightgrey;
  }
}
.form-container {
  padding: 1rem;

  &:hover {
    background-color: rgba(223, 228, 233, 0.2);

    &__footer {
      small {
        opacity: 1;
      }
    }
  }
}
</style>
