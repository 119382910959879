<template>
  <div v-click-outside="closeDropdown" class="priority">
    <div class="priority-value" @click="toggleDropDown">
      <div class="title">
        <div v-if="currentPriority" :class="['flag', currentPriority.value]">{{ currentPriority.value }}</div>
        <span>{{ currentPriority?.name || $t("todos.new_todo.priority") }}</span>
      </div>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7 8L12 3L17 8"
          stroke="#7E93A7"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17 16L12 21L7 16"
          stroke="#7E93A7"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <div v-if="showDropDown" class="priority-list" :class="{ inverted: !shouldShowBelow }">
      <div class="label">{{ $t("todos.new_todo.priority") }}</div>
      <div
        v-for="(item, key) in listOfPriorities"
        :key="key"
        :class="['item', { active: priority === item.value }]"
        @click="onSelect(item.value)"
      >
        <div :class="['flag', item.value]">{{ item.value }}</div>
        <div class="name">{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    priority: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    showDropDown: false,
    distanceToBottomEdge: 0,
  }),
  computed: {
    currentPriority() {
      return this.listOfPriorities.find(rw => rw.value === this.priority);
    },
    listOfPriorities() {
      const priorities = ["A", "B", "C", "D", "E"].map(priority => {
        return {
          name: this.$t(`todos.priorities.${priority}`),
          value: priority,
        };
      });

      return [...priorities];
    },
    shouldShowBelow() {
      return this.distanceToBottomEdge > 200;
    },
  },
  created() {
    document.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    onSelect(priority) {
      this.closeDropdown();
      this.$emit("select", priority);
    },
    toggleDropDown() {
      this.showDropDown = !this.showDropDown;
    },
    closeDropdown() {
      this.showDropDown = false;
    },
    onScroll() {
      const { bottom } = this.$el.getBoundingClientRect();
      this.distanceToBottomEdge = window.innerHeight - bottom;
    },
  },
};
</script>

<style lang="scss" scoped>
.priority {
  box-shadow: 0 0 0 1px #7e93a7 inset;
  display: flex;
  align-items: center;
  background: #fff;
  min-width: 200px;
  position: relative;
}
.priority-list {
  position: absolute;
  left: 0;
  top: 40px;
  background: #fff;
  width: 100%;
  z-index: 3;
  border: 1px solid #c9d2d7;
  box-shadow: 0px 2px 12px 1px rgba(0, 0, 0, 0.15);

  &.inverted {
    top: auto;
    bottom: 40px;
  }
}
.priority-list .item {
  display: flex;
  padding: 5px;
  cursor: pointer;
}
.priority-list .label {
  font-weight: 700;
  font-size: 16px;
  display: block;
  padding: 10px;
}
.flag {
  background: #eee;
  text-align: center;
  color: #fff;
  font-weight: bold;
  line-height: 1.9;
  width: 24px;
  height: 24px;
}
.flag.A {
  background: #c63040;
}
.flag.B {
  background: #e1633b;
}
.flag.C {
  background: #fa9334;
}
.flag.D {
  background: #faad4b;
}
.flag.E {
  background: #f8d06a;
}
.priority-list .item:hover,
.priority-list .item.active {
  background: #dfe4e9;
}
.priority-list .item .name {
  font-size: 13px;
  padding: 2px 8px;
  width: 100%;
}
.priority-value {
  display: flex;
  cursor: pointer;
  min-width: 220px;
}
.priority-value .title {
  width: 100%;
  font-size: 0.875rem;
  padding-inline: 0.5rem;
  display: inline-flex;
  align-items: center;
  gap: 0.75rem;
}
.priority-value svg {
  width: 50px;
  margin: 7px 0;
}
</style>
