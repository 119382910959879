<template>
  <dg-modal size="sm" :show="show" :options="modalOptions" @submit="$emit('submit')" @close="$emit('close')">
  </dg-modal>
</template>

<script>
export default {
  name: "EditTodoModalConfirm",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    modalOptions() {
      return {
        title: this.$t("todos.confirm_edit.title"),
        closeLabel: this.$t("todos.confirm_edit.cancel"),
        submitLabel: this.$t("todos.confirm_edit.confirm"),
      };
    },
  },
};
</script>
