<template>
  <dg-modal
    size="sm"
    :show="show"
    :options="{ ...modalOptions, loading: deleting }"
    @submit="deleteTodoItem"
    @close="$emit('close')"
  >
    <p>{{ $t("todos.confirm_delete.description") }}</p>
  </dg-modal>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "DeleteTodoModalConfirm",
  props: {
    taskId: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      deleting: false,
    };
  },
  computed: {
    modalOptions() {
      return {
        title: this.$t("todos.confirm_delete.title"),
        closeLabel: this.$t("todos.confirm_delete.cancel"),
        submitLabel: this.$t("todos.confirm_delete.submit"),
      };
    },
  },
  methods: {
    ...mapActions(["deleteTodo"]),
    async deleteTodoItem() {
      try {
        this.deleting = true;
        await this.deleteTodo(this.taskId);
        this.$dgNotification.show({
          type: "success",
          title: this.$t("todos.notifications.todo_removed"),
        });
        this.$emit("hide-description");
      } catch {
        this.$dgNotification.show({
          type: "error",
          title: this.$t("todos.notifications.todo_not_removed"),
        });
      } finally {
        this.deleting = false;
        this.$emit("close");
      }
    },
  },
};
</script>
