<template>
  <div class="todo-form-wrapper">
    <EditTodoConfirmModal
      :show="showEditConfirmModal"
      @close="showEditConfirmModal = false"
      @submit="confirmAcceptEdit"
    />
    <CloseTodoConfirmModal :show="showCloseConfirmation" @close="onEditCloseCancel" @submit="onEditCloseSubmit" />
    <form class="todo-item__wrapper" @submit.prevent="submit">
      <div class="todo-item">
        <template v-if="isUserDpo">
          <h4 class="todo-item__heading">{{ formHeading }}</h4>
          <div class="todo-item__body">
            <DgInput
              :attrs="{ value: fields.title, placeholder: $t('todos.new_todo.title_label') }"
              @input="onFieldChange('title', $event.target.value)"
            />
            <DgInput
              :attrs="{
                value: fields.description,
                rows: descriptionTotalRow,
                style: { 'min-height': '44px', 'max-height': '300px', resize: 'vertical' },
                placeholder: $t('todos.new_todo.description_label'),
              }"
              ref="description"
              input-type="large"
              @input="onDescriptionUpdate"
            />
          </div>
        </template>
        <div class="todo-item__footer">
          <div class="todo-item__footer-box small">
            <SelectAssignee
              :assignee="fields.assignee"
              @select="assigneeVal => onFieldChange('assignee', assigneeVal)"
            />
            <SelectPriority
              v-if="isUserDpo"
              :priority="fields.priority"
              @select="priorityVal => onFieldChange('priority', priorityVal)"
            />
            <DatePicker
              v-model="fields.deadline"
              mode="date"
              :popover="dateTimePopoverOptions"
              :min-date="new Date()"
              @input="selectedDate => onFieldChange('deadline', selectedDate)"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <dg-input
                  :attrs="{ value: inputValue, placeholder: $t('todos.new_todo.date_placeholder') }"
                  v-on="inputEvents"
                />
              </template>
            </DatePicker>
          </div>
          <div class="todo-item__footer-box">
            <DgButton native-type="button" variant-style="outline" @click="$emit('cancel')">
              {{ $t("todos.cancel") }}
            </DgButton>
            <DgButton native-type="submit" :disabled="disableSubmit">{{ $t("todos.save") }}</DgButton>
          </div>
        </div>
        <Hint v-if="showEmailMessage">{{ emailMessage }}</Hint>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import DatePicker from "v-calendar/lib/components/date-picker.umd";
import EditTodoConfirmModal from "../modals/EditTodoConfirmModal";
import CloseTodoConfirmModal from "../modals/CloseTodoConfirmModal";
import Hint from "../layout/Hint";
import SelectAssignee from "../SelectAssignee";
import SelectPriority from "../SelectPriority";
import { differenceInCalendarDays } from "date-fns";

export default {
  components: {
    SelectAssignee,
    SelectPriority,
    DatePicker,
    EditTodoConfirmModal,
    CloseTodoConfirmModal,
    Hint,
  },
  props: {
    isNewTask: {
      type: Boolean,
      required: true,
    },
    todoData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      showEditConfirmModal: false,
      acceptEdit: false,
      fields: {
        title: "",
        description: "",
        assignee: null,
        priority: "",
        deadline: "",
      },
    };
  },
  computed: {
    ...mapState({
      showCloseConfirmation: state => state.todos.showCloseConfirmation,
      user: state => state.user.userData,
      editForm: state => state.todos.editForm,
    }),
    isUserDpo() {
      return this.user.role.includes("dpo");
    },
    descriptionTotalRow() {
      const lineTotal = this.fields.description?.split("\n").length;
      if (!lineTotal || lineTotal <= 3) return 3;
      return lineTotal;
    },
    dateTimePopoverOptions() {
      return {
        placement: "top-start",
        visibility: "click",
      };
    },
    disableSubmit() {
      if (this.fields.title.trim() === "") return true;
      if (this.isPrestine) return true;
      return false;
    },
    formHeading() {
      return this.isNewTask ? this.$t("todos.new_todo.title_new") : this.$t("todos.new_todo.title_edit");
    },
    successMessage() {
      return this.isNewTask ? this.$t("todos.notifications.todo_created") : this.$t("todos.notifications.todo_updated");
    },
    daysTillDeadline() {
      try {
        const { deadline } = this.fields;
        if (!deadline.toString().length) return 0;
        return differenceInCalendarDays(new Date(deadline), new Date());
      } catch (error) {
        return 0;
      }
    },
    showEmailMessage() {
      return this.daysTillDeadline > 2;
    },
    emailMessage() {
      return this.daysTillDeadline > 13
        ? this.$t("todos.email_message.dpo.big")
        : this.$t("todos.email_message.dpo.small");
    },
    isPrestine() {
      const { title, description, assignee, priority, deadline } = this.fields;
      const {
        title: oldTitle,
        description: oldDescription,
        assignee_id: oldAssignee,
        priority: oldPriority,
        deadline_date: oldDeadline,
      } = this.todoData;
      return (
        title === oldTitle &&
        description === oldDescription &&
        assignee === oldAssignee &&
        priority === oldPriority &&
        deadline === oldDeadline
      );
    },
  },
  created() {
    if (Object.keys(this.todoData).length !== 0) {
      this.loadExistingTodoValues();
    }
  },
  mounted() {
    if (this.fields.description.length === "") return;
    this.resizeTextarea();
  },
  methods: {
    ...mapActions(["saveTodo"]),
    onFieldChange(fieldKey, newValue) {
      this.fields[fieldKey] = newValue;
    },
    onEditCloseCancel() {
      this.$store.commit("toggleCloseConfirmation", false);
    },
    onEditCloseSubmit() {
      this.$store.commit("toggleCloseConfirmation", false);
      if (this.editForm.type === "edit") {
        this.$store.commit("toggleAddForm", false);
        this.$store.commit("openEditForm", { type: "edit", id: this.editForm.nextId });
      } else {
        this.$store.commit("closeEditForm");
        this.$store.commit("toggleAddForm", true);
      }
    },
    loadExistingTodoValues() {
      const { title, description, assignee_id, priority, deadline_date } = this.todoData;
      this.fields = {
        title,
        description,
        assignee: assignee_id,
        priority,
        deadline: deadline_date,
      };
    },
    resizeTextarea() {
      const childNodes = this.$refs["description"].$el.childNodes.values();
      const textarea = childNodes.find(node => node.nodeName === "TEXTAREA");
      const heightLimit = 300;
      textarea.style.height = ""; /* Reset the height*/
      textarea.style.height = Math.min(textarea.scrollHeight, heightLimit) + "px";
    },
    onDescriptionUpdate(event) {
      const value = event.target.value;
      this.resizeTextarea();
      this.onFieldChange("description", value);
    },
    getFormData() {
      const { id = undefined } = this.todoData;
      const { title, description, assignee, priority, deadline } = this.fields;
      return {
        id,
        title,
        description,
        assignee_id: assignee,
        priority,
        deadline_date: deadline,
      };
    },
    confirmAcceptEdit() {
      this.acceptEdit = true;
      this.submit();
    },
    async submit() {
      if (!this.isUserDpo && !this.acceptEdit) {
        this.showEditConfirmModal = true;
        return false;
      }

      try {
        const newTodoData = this.getFormData();
        await this.saveTodo(newTodoData);
        this.$emit("cancel");
        this.$emit("success");
      } finally {
        this.$dgNotification.show({
          type: "success",
          title: this.successMessage,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// Main layout start
.todo-item {
  display: grid;
  gap: 1rem;

  &__body {
    display: flex;
    flex-flow: column;
    gap: 1rem;
  }

  &__heading {
    margin: 0;
  }
  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
  }
  &__footer-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

    &.small {
      gap: 0.5rem;
    }
  }
}
// Main layout end

.dg-calendar-input {
  font-size: 14px;
  line-height: 24px;
  padding-inline: 0.5rem;
  border-radius: 0;
  min-height: 44px;
  display: inline-flex;
  align-items: center;
}
</style>
