<template>
  <div class="contact">
    <div class="contact-image"></div>
    <div class="contact-text">
      <h3>{{ $t("todos.main.contact_title") }}</h3>
      <p>{{ $t("todos.main.contact_text") }}</p>
      <router-link style="text-decoration: none" :to="{ name: 'Dashboard', hash: '#customer_request' }">
        <dg-button class="contact-text__button" variant-style="secondary">
          {{ $t("todos.main.contact_appointment") }}
        </dg-button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contacts",
};
</script>

<style lang="scss" scoped>
.contact {
  background-color: $white;
  border: 1px #e6ebed solid;
  display: flex;
  min-height: 160px;

  .contact-image {
    background-image: url("../../images/Guardette.png");
    background-position: center;
    background-size: cover;
    width: 160px;
  }

  .contact-text {
    padding: 24px 36px;
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    color: #003349;

    h3 {
      @include font_weight("Semibold");
      font-size: 16px;
      text-transform: uppercase;
    }
    p {
      line-height: 20px;
    }

    .contact-text__button {
      display: inline-block;
    }
  }
}
</style>
