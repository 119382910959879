<template>
  <div class="overview">
    <div class="overview-card">
      <h2>{{ openTodosAmount }}</h2>
      <h4>{{ $t("todos.main.open_todos") }}</h4>
      <div class="overview__icon">
        <img src="../../images/checkmark.svg" alt="" />
      </div>
    </div>
    <div class="overview-card done">
      <h2>{{ doneTodosAmount }}</h2>
      <h4>{{ $t("todos.main.done_todos") }}</h4>
      <div class="overview__icon">
        <img src="../../images/shield.svg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import { TASK_STATE_OPEN, TASK_STATE_COMPLETED } from "../../constants";

export default {
  name: "Overview",
  computed: {
    ...mapState({
      todos: state => Object.values(state.todos.todos),
    }),
    openTodosAmount() {
      return this.todos.filter(task => task.state == TASK_STATE_OPEN).length;
    },
    doneTodosAmount() {
      return this.todos.filter(task => task.state == TASK_STATE_COMPLETED).length;
    },
  },
};
</script>

<style lang="scss" scoped>
.overview {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}
.overview-card {
  display: flex;
  flex-flow: column nowrap;
  background-color: $white;
  position: relative;
  height: 100px;
  overflow: hidden;
  padding: 1rem;

  &.done {
    background-color: #d2ecd2;
  }

  h2 {
    @include font_weight("Bold");
    font-size: 2.75rem;
    line-height: 42px;
    margin: 0;
  }
  h4 {
    @include font_weight("Bold");
    text-transform: uppercase;
    margin: 0;
  }

  .overview__icon {
    position: absolute;
    top: 8px;
    right: 0;
  }
}
</style>
