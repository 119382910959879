<template>
  <div class="preview">
    <div class="radio" :class="{ disabled: isUserDpo }" @click.stop="toggleTodoStatus">
      <img :src="statusIcon" alt="Status Icon" />
    </div>
    <div class="content">
      <h4>{{ todo.title }}</h4>
      <ul class="info">
        <li class="info-item">
          <DueDate :datetime="todo.deadline_date" />
        </li>
        <li class="info-item">
          <Assignee :assignee="assigneeName" />
        </li>
      </ul>
    </div>
    <Priority v-if="todo.priority" :priority="todo.priority" />
    <img src="../../images/arrow_expand.svg" alt="" :class="['preview-arrow', { rotated: areDetailsOpen }]" />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import { TASK_STATE_OPEN, TASK_STATE_COMPLETED } from "../../constants";
import DueDate from "../DueDate";
import Assignee from "../Assignee";
import Priority from "../Priority";
export default {
  name: "TodoPreview",
  components: {
    DueDate,
    Assignee,
    Priority,
  },
  props: {
    todo: {
      type: Object,
      required: true,
    },
    areDetailsOpen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      user: state => state.user.userData,
      availableAssignees: state => state.todos.availableAssignees,
    }),
    isUserDpo() {
      return this.user.role.includes("dpo");
    },
    statusIcon() {
      const openIcon = require("../../images/circle_open.svg");
      const doneIcon = require("../../images/circle_done.svg");
      return this.todo.state !== "done" ? openIcon : doneIcon;
    },
    assigneeName() {
      return this.availableAssignees[this.todo.assignee_id]?.name || this.$t("todos.labels.no_assignee");
    },
  },
  methods: {
    ...mapActions(["saveTodo"]),
    async toggleTodoStatus() {
      if (this.isUserDpo) return;
      const todoData = { ...this.todo };
      todoData.state = todoData.state === TASK_STATE_COMPLETED ? TASK_STATE_OPEN : TASK_STATE_COMPLETED;
      try {
        await this.saveTodo({
          ...todoData,
        });
      } finally {
        this.$dgNotification.show({
          type: "success",
          title: this.$t("todos.notifications.todo_updated"),
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.preview {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}
.content {
  flex: 1;

  h4 {
    margin-bottom: 0.25rem;
  }
}
.info {
  display: flex;
  list-style: none;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  padding: 0;
  gap: 0.75rem;
}
.info-item {
  display: flex;
  gap: 0.25rem;
  font-size: 12px;
}
.preview-arrow {
  color: #7e93a7;
  opacity: 0.6;
  transition: transform 0.2s ease-in-out;

  &.rotated {
    transform: rotate(180deg);
  }
}
.radio {
  &.disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
}
</style>
