<template>
  <dg-modal size="sm" :show="show" :options="{ ...modalOptions }" @submit="$emit('submit')" @close="$emit('close')">
    <p>{{ $t("todos.confirm_delete.description") }}</p>
  </dg-modal>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "CloseTodoConfirmModal",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    modalOptions() {
      return {
        title: this.$t("todos.confirm_edit_cancel.title"),
        closeLabel: this.$t("todos.confirm_edit_cancel.cancel"),
        submitLabel: this.$t("todos.confirm_edit_cancel.confirm"),
      };
    },
  },
};
</script>
