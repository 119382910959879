<template>
  <div class="assignee">
    <span class="assignee__icon">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8 2C6.3455 2 5 3.3455 5 5V5.75C5 7.4045 6.3455 8.75 8 8.75C9.6545 8.75 11 7.4045 11 5.75V5C11 3.3455 9.6545 2 8 2Z"
          fill="#254A5D"
        />
        <path
          d="M11.027 9.06952C10.2268 9.80002 9.16625 10.25 8 10.25C6.83375 10.25 5.77325 9.80002 4.973 9.06952C3.65225 9.50002 2.75 10.4833 2.75 11.75V14H13.25V11.75C13.25 10.4833 12.3478 9.50002 11.027 9.06952Z"
          fill="#254A5D"
        />
      </svg>
    </span>
    <span class="assignee__label">{{ $t("todos.labels.assignee") }}</span>
    <span class="assignee__text">{{ assignee }}</span>
  </div>
</template>

<script>
export default {
  props: {
    assignee: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.assignee {
  &__text {
    display: inline-block;
    padding: 0 4px;
  }

  &__label {
    margin-left: 4px;
    color: #718496;
  }
}
</style>
