<template>
  <dg-hint type="info"><slot /></dg-hint>
</template>

<script>
export default {
  name: "Hint",
};
</script>

<style lang="scss" scoped>
.dg-hint {
  padding: 0.75rem;
  border: 1px dashed;
  margin-top: 0.5rem;
}
</style>
