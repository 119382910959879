<template>
  <div class="todo-list">
    <div v-if="!todos.length" style="padding: 1rem">
      <Hint>{{ $t("todos.no_todos") }}</Hint>
    </div>
    <div v-else>
      <TodoItem v-for="(todo, index) in todos" :key="index" :todo="todo" />
    </div>
    <TodoAdd v-if="isUserDpo" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Hint from "../layout/Hint.vue";
import TodoItem from "./TodoItem.vue";
import TodoAdd from "./TodoAdd.vue";
export default {
  name: "TodoList",
  components: {
    Hint,
    TodoAdd,
    TodoItem,
  },
  computed: {
    ...mapState({
      todos: state => Object.values(state.todos.todos),
      showAddTaskForm: state => state.todos.showAddForm,
      user: state => state.user.userData,
    }),
    isUserDpo() {
      return this.user.role.includes("dpo");
    },
  },
};
</script>

<style lang="scss" scoped>
.todo-list {
  position: relative;
  background-color: $white;
  box-shadow: 0px 8px 16px rgb(0 0 0 / 15%);
  border: 1px #e6ebed solid;
  margin-bottom: 1rem;

  &::before,
  &::after {
    content: "";
    position: absolute;
    background-color: $white;
    border: 1px #e6ebed solid;
  }

  &:before {
    left: 4px;
    height: 4px;
    right: 4px;
    bottom: -5px;
    z-index: 2;
  }

  &:after {
    left: 8px;
    height: 4px;
    right: 8px;
    bottom: -9px;
    z-index: 1;
  }
}
</style>
