<template>
  <div class="due-date">
    <span class="due-date__icon">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.25 3.5H11.75V2H9.5V3.5H6.5V2H4.25V3.5H2.75C2.336 3.5 2 3.836 2 4.25V13.25C2 13.664 2.336 14 2.75 14H13.25C13.664 14 14 13.664 14 13.25V4.25C14 3.836 13.664 3.5 13.25 3.5ZM12.5 12.5H3.5V5.75H12.5V12.5Z"
          fill="#254A5D"
        />
        <path d="M6.5 7.25H5V8.75H6.5V7.25Z" fill="#254A5D" />
        <path d="M8.75 7.25H7.25V8.75H8.75V7.25Z" fill="#254A5D" />
        <path d="M6.5 9.5H5V11H6.5V9.5Z" fill="#254A5D" />
        <path d="M8.75 9.5H7.25V11H8.75V9.5Z" fill="#254A5D" />
        <path d="M11 7.25H9.5V8.75H11V7.25Z" fill="#254A5D" />
        <path d="M11 9.5H9.5V11H11V9.5Z" fill="#254A5D" />
      </svg>
    </span>
    <span class="due-date__label">{{ $t("todos.labels.duedate") }}</span>
    <span class="due-date__text">{{ duedate }}</span>
  </div>
</template>

<script>
export default {
  props: {
    datetime: {
      type: String,
    },
  },
  computed: {
    duedate() {
      if (this.datetime) {
        return this.relativeTime(this.datetime);
      }
      return this.$t("todos.labels.no_duedate");
    },
  },
};
</script>

<style lang="scss" scoped>
.due-date {
  &__text {
    display: inline-block;
    padding: 0 4px;
  }

  &__label {
    margin-left: 4px;
    color: #718496;
  }
}
</style>
